import React from 'react'
import { InfoCircleTwoTone } from '@ant-design/icons'
import { LYSIcon } from '@core/components/Primitives'
import { useTranslation } from '@core/i18n/i18n'
import style from './index.module.less'

/**
 * Used for Corona Hint. Remove after situation.
 */
const SmallBenefitsHint: React.FC = () => {
  const { t } = useTranslation()

  return (
    <>
      <div className={style.hintContainer}>
        <div className={style.points}>
          <LYSIcon className={style.icon} component={InfoCircleTwoTone} />
          <div> {t('coronaHint.text')}</div>
        </div>
      </div>
    </>
  )
}

export default SmallBenefitsHint
