import * as React from 'react'
import cn from 'classnames'
import PageLayout from '../../prismic/components/PageLayout'
import { IPageLayout } from '../../prismic/types'
import SmallBenefitsHint from '../Benefits/SmallBenefitsHint'
import style from './index.module.less'

interface Props {
  pageLayout?: IPageLayout
  className?: string
}

const Home: React.FC<Props> = ({ pageLayout, className }) => {
  return (
    <>
      <div className={cn(style.landingpageSmallBenefitsHint, className)}>
        <SmallBenefitsHint />
      </div>
      <div className={style.landingpageLayout}>
        {pageLayout && <PageLayout layout={pageLayout} />}
      </div>
    </>
  )
}

export default Home
